define("discourse/plugins/docker_manager/discourse/components/docker-manager/console", ["exports", "@glimmer/component", "@ember/render-modifiers/modifiers/did-insert", "@ember/render-modifiers/modifiers/did-update", "discourse-common/utils/decorators", "@ember/template-factory", "@ember/component"], function (_exports, _component, _didInsert, _didUpdate, _decorators, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _Console;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let Console = _exports.default = (_class = (_Console = class Console extends _component.default {
    scrollToBottom(element1) {
      if (this.args.followOutput) {
        element1.scrollTop = element1.scrollHeight;
      }
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div
        class="console-logs"
        {{didInsert this.scrollToBottom}}
        {{didUpdate this.scrollToBottom @output}}
      >
        {{~@output~}}
      </div>
    
  */
  {
    "id": "rUSYTm9D",
    "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"console-logs\"],[4,[32,0],[[30,0,[\"scrollToBottom\"]]],null],[4,[32,1],[[30,0,[\"scrollToBottom\"]],[30,1]],null],[12],[1,[30,1]],[13],[1,\"\\n  \"]],[\"@output\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/docker_manager/discourse/components/docker-manager/console.js",
    "scope": () => [_didInsert.default, _didUpdate.default],
    "isStrictMode": true
  }), _Console), _Console), (_applyDecoratedDescriptor(_class.prototype, "scrollToBottom", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "scrollToBottom"), _class.prototype)), _class);
});